import React, { useState, useEffect } from 'react';
import axios from 'axios';

function SearchBar({ searchTerm, onChange, onKeyPress, handleSearch, setSearchTerm }) {
  const [correctedTerm, setCorrectedTerm] = useState('');
  const [showSuggestion, setShowSuggestion] = useState(false);
  const debounceDelay = 500;

  useEffect(() => {
    if (searchTerm.trim().length <= 2) {
      setCorrectedTerm('');
      setShowSuggestion(false);
      return;
    }

    const handler = setTimeout(() => {
      checkSpelling(searchTerm);
    }, debounceDelay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const checkSpelling = async (text) => {
    try {
      //const response = await axios.post('http://127.0.0.1:5000/api/spellcheck', { text }); // LOCAL
      const response = await axios.post('https://search.versatileai.net/api/spellcheck', { text }); // PROD
    
      const { corrected, was_corrected } = response.data;

      if (was_corrected && corrected !== text) {
        setCorrectedTerm(corrected);
        setShowSuggestion(true);
      } else {
        setShowSuggestion(false);
      }
    } catch (error) {
      console.error('Error fetching spell check:', error);
      setShowSuggestion(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value); // Update parent's searchTerm
    onChange(e); // Keep the original onChange handler
    setShowSuggestion(false); // Hide suggestion while typing
  };

  const handleSuggestionClick = () => {
    setSearchTerm(correctedTerm); // Update parent's searchTerm
    setShowSuggestion(false);
    console.log("search bar term: ", correctedTerm);
    // Wait for state update to complete before searching
    setTimeout(() => {
      handleSearch(correctedTerm); // Pass the corrected term directly
    }, 0);
  };

  const handleCloseSuggestion = () => {
    setShowSuggestion(false); // Close the suggestion when the X is clicked
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch(searchTerm); // Pass the current searchTerm
    } else {
      onKeyPress(event);
    }
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        placeholder="Search products..."
        value={searchTerm} // Use parent's searchTerm
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        className="border rounded-lg p-2 w-full"
        style={{ minWidth: '700px' }}
      />
      
      {showSuggestion && correctedTerm && (
        <div className="absolute top-full left-0 mt-1 w-full bg-white border rounded-lg p-2 shadow-lg">
          <div className="flex justify-between items-center">
            <p className="text-sm text-gray-600">
              Did you mean:{' '}
              <button
                onClick={handleSuggestionClick}
                className="text-blue-600 hover:underline focus:outline-none"
              >
                {correctedTerm}
              </button>
            </p>
            <button
              onClick={handleCloseSuggestion}
              className="text-gray-400 hover:text-gray-600 focus:outline-none"
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchBar;
